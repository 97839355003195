html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  background-color: white;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
}

.App {
  width: 100%;
  height: 100%;
}

.Edge {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}
.Edge--void {
  position: absolute;
  display: block;
  top: 5%;
  right: 5%;
  bottom: 5%;
  left: 5%;
  background-color: inherit;
}

.Transfer {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
  background-color: transparent;
  z-index: 1;
}
.Transfer--active {
  z-index: 2;
}
